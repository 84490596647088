import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logo/logo.png'
import './style.scss'
const footerLinks = [
    {
        title: 'Menu', menus: [
            { name: 'Ana Sayfa', route: '/' },
            { name: 'Deneyimlerimiz', route: 'practice' },
            { name: 'Avukatlarımız', route: 'team' },
            { name: 'Güncel Davalar', route: 'case' },
            { name: 'Yazılarımız', route: 'blog' },
        ]
    },
    {
        title: 'Deneyimlermiz', menus: [
            { name: 'Aile Hukuku', route: 'home' },
            { name: 'Ceza Hukuku', route: 'home' },
            { name: 'Ticaret Hukuku', route: 'home' },
            { name: 'İdare, Vergi Hukuku', route: 'home' },
            { name: 'İş Hukuku', route: 'home' },
            { name: 'İcra Hukuku', route: 'home' },
        ]
    },
    {
        title: 'Bizimle İletişime Geçin', menus: [
            { name: 'Ofis Adresimiz' },
            { name: 'Ragıp Tüzün Mahallesi Serdar Sokak Seyran Plaza No: 122/4', },
            { name: 'Yenimahalle/Ankara', },
            { name: 'Telefon: 551 966-1868', },
            { name: 'Email: av.ayseinceoglu@gmail.com', },
        ]
    },
]

const FooterArea = () => {
    return (
        <footer className="footerArea">
            <div className="footerTopArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footerLogo">
                                <Link to="/">
                                    <img src={logo} alt="" />
                                </Link>
                                <p>İnceoglu Hukuk Bürosu; <br /> Av. İsmail İnceoglu, Av. Ayşe Büşra PEHLİVAN ve Av. Melih İnceoglu tarafından kurulmuş olup, Yenimahalle/Ankara’da bulunan avukatlık ofisinde faaliyet göstermektedir.</p>
                            </div>
                        </div>
                        {footerLinks.map((menu, i) => (
                            <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footerWrap">
                                    <h3>{menu.title}</h3>
                                    <ul>
                                        {menu.menus.map((item, i) => (
                                            <li key={i}>{item.route ? <Link to={`/${item.route}`}>{item.name}</Link> : `${item.name}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="footerBottomArea">
                <div className="container">
                    <div className="footerBottomContent">
                        <div className="row">
                            <div className="col-md-8 col-sm-10 col-12">
                                <span>Gizlilik Sözleşmesi | © 2024 İnceoglu. Tüm Haklar Saklıdır.</span>
                            </div>
                            <div className="col-md-4 col-sm-2 col-12">
                                <ul className="socialListFooter">
                                    <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default FooterArea